import { Injectable } from '@angular/core';
import { Requerimiento } from '../model/requerimiento';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  requerimientos: Requerimiento[] = [];
  constructor() {
  }

  getProducto() {
    if (localStorage.getItem('requerimientos') === null) {
      this.requerimientos = [];
    } else {
      this.requerimientos = JSON.parse(localStorage.getItem('requerimientos'));
    }
    return this.requerimientos;
  }
  addProducto(requerimiento: Requerimiento) {
    this.requerimientos.push(requerimiento);
    let productos = [];
    if (localStorage.getItem('requerimientos') === null) {
      productos = [];
      productos.push(requerimiento);
      localStorage.setItem('requerimientos', JSON.stringify(productos));
    } else {
      productos = JSON.parse(localStorage.getItem('requerimientos'));
      productos.push(requerimiento);
      localStorage.setItem('requerimientos', JSON.stringify(productos));
    }
  }
  deleteProducto(id_producto_precio) {
    for (let i = 0; i < this.requerimientos.length; i++) {
      if (id_producto_precio === this.requerimientos[i].id_producto_precio) {
        this.requerimientos.splice(i, 1);
        localStorage.setItem('requerimientos', JSON.stringify(this.requerimientos));
      }
    }
  }
}